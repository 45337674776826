<template>
  <!-- 编辑车辆信息 -->
  <div class="facilityBox">
    <div
      class="edit-car"
      v-loading="loading"
    >
      <el-breadcrumb
        separator="/"
        style="margin:10px 0 0 0"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/bigCustomerManage/bigCarManage/bigCarManage' }">车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑车辆信息</el-breadcrumb-item>
      </el-breadcrumb>

      <el-form
        :model="carForm"
        :rules="carRule"
        ref="carForm"
        label-width="160px"
        inline
        @submit.native.prevent
      >
        <div class="title">证照信息</div>
        <div class="content">
          <div class="upBox">
            <UploadImg
              ref="vehicleFace"
              :loading="vehicleImg.vehicleFace.loading"
              :uploaded="vehicleImg.vehicleFace.uploaded"
              :picUrl="vehicleImg.vehicleFace.picUrl"
              @update:file="updateFile"
              :fileType="'Vehicle'"
              @change="upVehicleFace(vehicleImg.vehicleFace)"
              @deleteImg="deleteImg(vehicleImg.vehicleFace)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              车辆行驶证正页(正面)
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="vehiclePageOn"
              :loading="vehicleImg.vehiclePageOn.loading"
              :uploaded="vehicleImg.vehiclePageOn.uploaded"
              :picUrl="vehicleImg.vehiclePageOn.picUrl"
              @update:file="updateFile"
              :fileType="'Vehicle'"
              @change="upVehicleBack(vehicleImg.vehiclePageOn)"
              @deleteImg="deleteImg(vehicleImg.vehiclePageOn)"
            >
            </UploadImg>
            <div class="upText">
              <span class="required">*</span>
              车辆行驶证副页(正面)
            </div>
          </div>
          <!-- <div class="upBox">
            <UploadImg
              ref="vehicleBack"
              :loading="vehicleImg.vehicleBack.loading"
              :uploaded="vehicleImg.vehicleBack.uploaded"
              :picUrl="vehicleImg.vehicleBack.picUrl"
              @update:file="updateFile"
              :fileType="'Vehicle'"
              @change="upVehiclePageOn(vehicleImg.vehicleBack)"
              @deleteImg="deleteImg(vehicleImg.vehicleBack)"
            >
            </UploadImg>
            <div class="upText">
              车辆行驶证副页(反面)
            </div>
          </div> -->
          <div class="upBox">
            <UploadImg
              ref="vehicleRTP"
              :loading="vehicleImg.vehicleRTP.loading"
              :uploaded="vehicleImg.vehicleRTP.uploaded"
              :picUrl="vehicleImg.vehicleRTP.picUrl"
              @update:file="updateFile"
              :fileType="'Vehicle'"
              @change="upvehicleRTP(vehicleImg.vehicleRTP)"
              @deleteImg="deleteImg(vehicleImg.vehicleRTP)"
            >
            </UploadImg>
            <div class="upText">
              道路运输证
            </div>
          </div>
        </div>

        <div class="title">基本信息</div>
        <div
          class="content content2"
          style="justify-content: left"
        >
          <el-form-item
            :label="it.label"
            :prop="it.prop"
            v-for="(it, idx) in formList"
            :key="idx"
          >
            <el-input
              v-model="carForm[it.prop]"
              :placeholder="it.placeholder"
              :maxlength="it.maxLength"
            >
              <template
                #append
                v-if="it.unit"
              >
                {{ it.unit }}
              </template>
            </el-input>
          </el-form-item>
        </div>

        <div class="footer-btns">
          <el-button
            size="medium"
            icon="el-icon-arrow-left"
            @click="routerBack()"
          >返回</el-button>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-check"
            @click="commitCar"
          >确定
          </el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { formList, carRule } from './data.js'
import { CarDetail, EditCar } from '@/api/bigCustomerManage/index.js'
import { uploadImg } from '@/api/common/common.js'

export default {
  data() {
    return {
      loading: false,
      currentFile: [], //当前正在操作的文件
      formList,
      carRule,
      carForm: {},
      // 证件照片
      vehicleImg: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      searchData: {}
    }
  },
  created() {
    this.searchData = this.$route.query
    let { userId, vehicleCarNumber } = this.$route.query
    this.getCarDetail({
      userId,
      vehicleCarNumber
    })
  },
  methods: {
    // 获取车辆详情ar
    async getCarDetail(params) {
      try {
        this.loading = true
        let { data = {} } = await CarDetail(params)
        this.carForm = data

        this.vehicleImg.vehicleFace.picUrl = data.VehicleLicenseFrontPageURL
        this.vehicleImg.vehicleBack.picUrl = data.VehicleLicenseSubPageBackURL
        this.vehicleImg.vehiclePageOn.picUrl = data.VehicleLicenseSubPageOnURL
        this.vehicleImg.vehicleRTP.picUrl = data.VehicleRTPUTL
      } finally {
        this.loading = false
      }
    },

    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },

    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },

    // 上传行驶证正面
    upVehicleFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleFace.dialog();
        });
    },

    // 上传行驶证副页（正面）
    upVehicleBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehiclePageOn.dialog();
        });
    },

    // 上传车辆道路运输证照片
    upvehicleRTP(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadImg(formData)
        .then(({ data = '' }) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = data;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleRTP.dialog();
        });
    },

    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/bigCustomerManage/bigCarManage/bigCarManage",
        query: {
          ...this.searchData
        }
      });
    },

    // 提交编辑车辆
    commitCar() {
      this.$confirm('此操作将修车辆信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loading = true
          this.carForm.VehicleLicenseFrontPageURL = this.vehicleImg.vehicleFace.picUrl
          this.carForm.VehicleLicenseSubPageOnURL = this.vehicleImg.vehiclePageOn.picUrl
          // this.carForm.VehicleLicenseSubPageBackURL = this.vehicleImg.vehicleBack.picUrl
          this.carForm.VehicleRTPUTL = this.vehicleImg.vehicleRTP.picUrl

          await EditCar(this.carForm)
          this.$message({
            message: '修改成功',
            type: 'success'
          });

          setTimeout(() => {
            this.routerBack()
          }, 1000);

        } finally {
          this.loading = false
        }
      }).catch(() => {
      });
    }
  },
  components: {
    UploadImg
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/variable.scss';

.edit-car {
  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .footer-btns {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
}
</style>

<style>
/* input宽度 */
.edit-car .el-form .content2 .el-form-item {
  width: 400px !important;
}

.edit-car .el-form .content2 .el-input,
.edit-car .el-form .content2 .el-date-editor,
.edit-car .el-form .content2 .el-select {
  width: 240px !important;
}

.edit-car .el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>